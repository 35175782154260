<template>
  <a-modal
      v-drag-modal
      title="视频"
      :visible="previewVisible"
      destroyOnClose
      @cancel="previewVisible = false"
  >
    <video :src="video.url" :poster="video.cover"/>
  </a-modal>
</template>

<script>

export default {
  name: "VideoPreviewModal",
  components: {},
  data() {
    return {
      previewVisible: false,
      record: undefined,
      video: {}
    }
  },
  methods: {
    preview(video) {
      this.previewImage = imgSrc;
      this.previewVisible = true
      this.video = video
      // this.$nextTick( this.imgUrlEdit)
    },
  }
}
</script>

<style scoped lang="less">

::v-deep {
  .ant-modal-body {
    max-height: 600px;
    overflow: auto
  }

}
</style>